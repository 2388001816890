<template>
    <div class="menu">
        <router-link to="/">
<!--            <img :src="require('../assets/images/index.png')" alt="">-->
            <img :src="require('../assets/images/index1.png')" alt="">
            <p>首页</p>
        </router-link>
        <router-link v-for="(item ,index) in menuList" :key="item.name" :to="item.path"
                     :active-class="getActive({...item},index)"
                     v-if="$store.state.mainMenus.includes(item.name)"
                     :class="$route.path == item.path ? 'active' : ''" @click.native="changeMenu(item)">
            <img v-if="$route.path == item.path" :src="item.imgTap" alt="">
            <img v-else :src="item.img" alt="">
            <p>{{ item.name }}</p>
<!--            <img src="../assets/images/right_icon.png" alt="" v-if="!item.not_right">-->
        </router-link>
    </div>
</template>

<script>
export default {
    name: "sideBar",
    data() {
        return {
            menuList: [
                {path: '/PatientManagement', name: '患者管理', imgTap: require('../assets/images/huanzhe.png'),img: require('../assets/images/huanzhe1.png')},
                {path: '/Assess', name: '评估测试', imgTap: require('../assets/images/pinggu.png'),img: require('../assets/images/pinggu1.png')},
                {path: '/ReportCenter', name: '报告中心', imgTap: require('../assets/images/baogao.png'),img: require('../assets/images/baogao1.png')},
                // {path: '/TermMonitor', name: '终端监控', img: require('../assets/images/jiankong.png')},
                {path: '/SystemSetting', name: '系统设置', imgTap: require('../assets/images/shezhi.png'),img: require('../assets/images/shezhi1.png')},
            ]
        };
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        changeMenu(item) {
            this.$store.commit('setCmenu', item.name)
        },
        getActive({path, name}, item) {
            console.log('this.$route',this.$route)
            if (this.$route.path === path) {
                this.$store.commit('setCmenu', name)
            }

            return 'active'
        }
    },
}
</script>

<style scoped lang="scss">
.menu {
    box-sizing: border-box;
    min-width: 187px;
    height: 100%;
    margin-right: 20px;
}

.menu a {
    display: block;
    overflow: hidden;
    position: relative;
    width: 187px;
    height: 112px;
    color: #8290A6;
    font-size: 16px;
    text-align: center;
    img {
        display: block;
        margin: 15px auto;
    }
}

.menu a.active {
    background: #FFFFFF;
    border-radius: 0px 20px 20px 0px;
}

.menu a img:first-child {
    width: 40px;
    //margin-right: 30px;
}

//.menu a img:last-child {
//    position: absolute;
//    top: 50%;
//    transform: translate(0, -50%);
//    right: 35px;
//}
</style>
